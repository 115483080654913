import { useCatalogStore } from "../stores/CatalogStore.js";
import { useRouterStore } from "../stores/RouterStore.js";
import {handlePagination} from "../utils/handlePagination.js";

export default defineNuxtRouteMiddleware((to, from) => {
    return handlePagination(to, () => {
        if (to.name.startsWith('search')) {
            return useCatalogStore().catalogResource?.paginator;
        } else {
            const routerStore = useRouterStore();
            const page = routerStore.getCurrentPage();
            return page?.catalog?.paginator;
        }
    });
});
